import React from 'react';
import logo from './logo.svg';
import './App.css';
import Lorem from 'react-lorem-component';
import { SocialIcon } from 'react-social-icons';
import { MdMenu } from 'react-icons/md'

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <StickyHeader>
          <Header />
        </StickyHeader>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Clock />
        </header>
        <Lorem count="20"/>
      </div>
    );
  }
}

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {
    return (
      <div>
        <h1>Hello World!</h1>
        <h2>It is {this.state.date.toLocaleTimeString()}</h2>
      </div>
    )
  }
}

class StickyHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollingLock: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 0) {
      console.log("should lock");
      this.setState({
        scrollingLock: true
      });
    } else if (window.scrollY < 0) {
      console.log("not locked" );
      this.setState({
        scrollingLock: false
      });
    }
  }

  render() {
    return (
      <div style={{ width: "100%", backgroundColor: "white", zIndex: "20", position: this.state.scrollingLock ? "fixed" : "relative"}}>
              {this.props.children}
      </div>
    )
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth
    };

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({width: window.innerWidth});
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    if (isMobile) {
      return (
        <div className="header">
          <h1 className="title">Paul Jones</h1>
          <div className="social-icons">
            <MdMenu style={{width: "50px", height: "50px", display: "inline-block"}} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="header">
          <h1 className="title">Paul Jones</h1>
          <div className="social-icons">
            <SocialIcon url="https://twitter.com/pjonesnz" />
            <SocialIcon url="https://linkedin.com/in/pauljonesnz/" />
          </div>
        </div>
      )
    }
  }
}

export default App;
